*,
*::before,
*::after {
	box-sizing: inherit;
	padding: 0;
	margin: 0;
}

html {
	box-sizing: border-box;
}

body{
  font-family: $opensans-c;
}

/* COMMON STYLES */

.flex{
  display: flex;
  justify-content: space-between;
}

h2{
  font-size: 30px;
  font-weight: 700;
  color: #f3f3f3;
  font-family: $montserrat;
}

h3{
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  font-family: $montserrat;
}

.btn{
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  display: inline-block;
  text-decoration: none;
  background-color: #fccf55;
  line-height: 40px;
  padding: 0 40px;
}

.panel-flexible .panel-separator{
  margin: 0;
}

.panels-flexible-region-1-center, .panels-flexible-region-3-center, .panels-flexible-region-2-center, .panels-flexible-region-5-center, .section-form-13-center, .panels-flexible-region-1-______________, .panels-flexible-region-1-______________{
  width: 100%;
}
.panels-flexible-region-10-center{
  float: none;
}
.panels-flexible-1 .panels-flexible-row{
  padding: 0;
}


.grid-container-inside-last{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  .small-6, .large-6{
    width: 50%;
    flex-basis: auto;
  }
}

body {
  background-color: rgb(243, 243, 243);
}

#block-system-main .content {
  min-height: 200px;
  text-align: center;
  vertical-align: middle;
  font-size: 24px;
}

#block-system-main .content > div {
  text-align: left;
}

.unpublished-message {
  background-color: lightcoral;
  padding: 10px;
  text-align: center;
}

nav{
  display: none !important;
}

/* HEADER */

header, footer{
  padding: 50px 0;
  background-color: #f3f3f3;
  .logo{
    width: 130px;
    height: 55px;
    background: url("../images/logo.png") no-repeat center center;
    display: inline-block;
    cursor: pointer;
  }
  .menu{
    list-style: none;
    display: flex;
    justify-content: space-between;
    line-height: 55px;
    li{
      display: inline-block;
      vertical-align: middle;
      a{
        color: #333333;
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        &:hover{
          color: #498bf3;
        }
        &.active{
          background-color: #fed148;
          line-height: 30px;
          padding: 0 15px;
          display: inline-block;
          &:hover{
            background-color: #498bf3;
            color: #333333;
          }
        }
      }
    }
  }
}

.main{
  padding: 50px 0 150px 0;
  background: #f3f3f3 url("../images/image-main.png") no-repeat center top 80px;
  .grid-container{
    position: relative;
    &:after{
      content: 'а цифра 10 нам просто нравится ';
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      color: #333333;
      font-family: $badscript;
      display: inline-block;
      width: 190px;
      position: absolute;
      bottom: -70px;
      right: 300px;
      transform: rotate(-10deg);
    }
  }
  .dates{
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    padding-left: 150px;
    hr{
      width: 140px;
      height: 1px;
      background-color: #b1b1b1;
      margin: 0 15px;
      display: inline-block;
      vertical-align: middle;
      border: 0;
    }
  }
  .title{
    font-size: 80px;
    line-height: 100px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 90px 0 30px 0;
  }
  .workers{
    position: absolute;
    right: 90px;
    top: 60%;
    transform: translate(0, -60%);
    p{
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      color: #333333;
      &:before{
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid transparent;
        border-left: 8px solid #4c1699;
        border-top: 6px solid transparent;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .date-start{
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    padding-left: 100px;
    &:before{
      content: '';
      width: 0;
      height: 0;
      border-bottom: 6px solid transparent;
      border-left: 8px solid #4c1699;
      border-top: 6px solid transparent;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.about{
  background-color: #498bf3;
  position: relative;
  &:after{
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #333333;
    visibility: visible;
  }
  .panels-flexible-row-1-1-inside{
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    max-width: 1200px;
    margin: 0 auto;
  }

  #scene{
    pointer-events: all !important;
  }
  .text-about{
    padding: 70px 120px 60px 0;
    p{
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: #ffffff;
      margin-top: 20px;
      margin-bottom: 40px;
      font-family: $opensans;
    }

    .btn:hover{
      background-color: #f3464f;
    }
  }

  .tags{
    padding: 45px 0 0px 65px;
    position: relative;
    z-index: 2;
    pointer-events: all !important;
    .view-header{
      display: none;
    }
    .item-list{
      ul{
        list-style: none;
        li{
          margin: 0;
        }
      }
    }
    .tags-wrapper{
      padding: 25px 0 0 250px;
      position: relative;
      z-index: 5;
    }
    .tmonkey{
      display: inline-block !important;
      width: 210px;
      height: 440px;
      background: url("../images/monkey-astro.png") no-repeat center center;
      z-index: -1;
      margin-right: 0;
    }
    a{
      border: 1px dotted #f3f3f3;
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #f3f3f3;
      font-family: $montserrat;
      text-decoration: none;
      line-height: 30px;
      padding: 0 10px;
      margin-bottom: 10px;
      margin-right: 10px;
      position: relative;
      z-index: 5;
    }
  }
}

.clients{
  background-color: #fccf55;
  position: relative;
  z-index: 2;
  overflow: hidden;
  &:after{
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #333333;
  }
  .clients-list{
    padding: 70px 0 60px 0;
    position: relative;
    z-index: 2;
  }
  h2{
    margin-bottom: 10px;
  }
  img{
    margin-top: 40px;
    margin-right: 40px;
  }
  .btn{
    color: #ffffff;
    background-color: #498bf3;
    line-height: 40px;
    padding: 0 35px;
    margin-top: 40px;
    &:hover{
      background-color: #f3464f;
    }
  }

  .clients-img{
    position: relative;
    &:before{
      width: 200px;
      background-color: #f3464f;
      height: 100%;
      display: inline-block;
      content: '';
      position: relative;
      z-index: 2;
    }
    &:after{
      position: absolute;
      left: 30px;
      bottom: 0;
      z-index: 5;
      background: url("../images/clients-img.png") no-repeat left bottom;
      content: '';
      width: 743px;
      height: 100%;
      display: block;
    }
  }
}

.section-form{
  background-color: #eaeaea;
  padding: 100px 0 100px 0;
  .monkey{
    position: relative;
    .fmonkey{
      display: inline-block;
      background: url("../images/monkey-astro2.png") no-repeat center center;
      width: 352px;
      height: 417px;

    }
    span{
      color: #333333;
      font-family: $badscript;
      font-size: 30px;
      font-weight: 400;
      line-height: 38px;
      transform: rotate(-10deg);
      display: inline-block;
      width: 150px;
      position: absolute;
      right: -120px;
      top: 50px;
    }

  }
  .send{
    padding-right: 200px;
    h2{
      color: #f2464e;
      font-size: 40px;
      font-weight: 700;
    }

    .webform-component--text{
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: #333333;
      font-family: $montserrat;
      padding: 40px 0 40px 0;
      margin: 0;
    }

    form{
      #edit-submitted-file-upload{
        display: none;
      }
      .webform-component--file{
        position: relative;
        .form-submit{
          display: none;
        }
        &:before{
          content: 'Резюме ';
          color: #333333;
          font-family: $opensans-c;
          font-size: 18px;
          font-weight: 700;
        }

        &:after{
          color: #7d7d7d;
          font-family: $opensans-c;
          font-size: 13px;
          font-weight: 700;
          content: 'MS World, pdf, jpeg';
        }
        .description{
          display: none;
        }
      }
      .webform-component--file .element-invisible{
        color: #ffffff;
        font-family: $opensans-c;
        font-size: 18px;
        font-weight: 700;
        width: 100px;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        text-align: center;
        background-color: #498bf3;
        top: 0;
        clip: auto;
        right: 0;
        span{
          display: none;;
        }
      }
      select{
        width: 100%;
        height: 40px;
        background-color: #ffffff;
        font-size: 18px;
        font-weight: 700;
        color: #7d7d7d;
        padding: 0 20px;
        border: 0;
        outline: 0;
        margin-bottom: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        &::-ms-expand {
          display: none;
        }

        option{
          color: #333333;
          font-family: $opensans-c;
          font-size: 18px;
          font-weight: 700;
          height: 40px;
          line-height: 40px;
          border:0;
          outline: 0;
          &:hover{
            background-color: #498bf3;
          }
        }
      }

      .webform-component-select{
        position: relative;
        &:after{
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-right: 6px solid transparent;
          border-left: 6px solid transparent;
          border-top: 8px solid #455560;
          position: absolute;
          right: 15px;
          top: 18px;
        }
      }

      input{
        width: 100%;
        height: 40px;
        background: transparent;
        border: 0;
        outline: 0;
        border-bottom: 3px solid #ffffff;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 700;
        color: #7d7d7d;
        &::placeholder{
          font-size: 18px;
          font-weight: 700;
          color: #7d7d7d;
        }
        &.error{
          border-bottom: 3px solid #f3464f;
          &:after{
            content: '';
            width: 17px;
            height: 17px;
            display: inline-block;
            background-color: #f3464f;
            position: absolute;
            right: 0;
            top:0;
          }
        }
      }

      textarea{
        width: 100%;
        height: 70px;
        background: transparent;
        border: 0;
        outline: 0;
        border-bottom: 3px solid #ffffff;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 700;
        color: #7d7d7d;
        &::placeholder{
          font-size: 18px;
          font-weight: 700;
          color: #7d7d7d;
        }
      }

      .form-checkboxes{
        width: 300px;
        label.option{
          font-size: 14px;
          line-height: 15px;
          font-weight: 700;
          color: #7d7d7d;
        }
      }

      [type="checkbox"]:not(:checked),
      [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
      }

      [type="checkbox"]:not(:checked) + label,
      [type="checkbox"]:checked + label {
        position: relative;
        padding-left: 40px;
        vertical-align: bottom;
        cursor: pointer;
        line-height: 16px;
        vertical-align: middle;
        display: inline-block;
      }


      [type="checkbox"]:not(:checked) + label:before,
      [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0; top: 0;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        border: 0;
        background-color: #498bf3;
      }

      [type="checkbox"]:not(:checked) + label:after,
      [type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: 30px;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAKCAYAAABv7tTEAAAAxElEQVQoU42RMYoCURBEX2lksJF4DpPNPYEnEDFWVkzXzAEDzRYRzfUAgomH2AsYeBEFKRnpGb6iYIfd9ap/1xcflu1foAn09AljewgsQru/Q7a/gLOky7OJ7T6wSvoD2W4AB+AEdCRdC8ELYChpmUP/wHcI15IGsb0DbIHihLGkWT7LoVZsqgU4BY7ABqhErwTuULi2gR1QDZGTDZmkSXprmZ7tbrin87mkPOqHeojc9gj4C8VLoHxeamM7A+qSft794Q2vmkIibT8hJwAAAABJRU5ErkJggg==") no-repeat center center;
        height: 30px;
        color: #ffffff;
      }


      [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }
      [type="checkbox"]:checked + label:after {
        opacity: 1;
      }

      input[type="submit"]{
        background-color: #f3464f;
        border: 0;
        outline: 0;
        color: #ffffff;
        margin-top: 20px;
        width: 180px;
        &:hover{
          background-color: #333333;
        }
        &:disabled{
          opacity: 0.5;
          &:hover{
            background-color: #f3464f
          }
        }
      }
    }
  }
}

footer{
  .copyright{
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    letter-spacing: -0.19px;
    line-height: 37.27px;
    font-family: $montserrat;
    padding-top: 40px;

    span{
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPCAYAAAACsSQRAAABSklEQVQ4T6WTyyuEURiHn9/HCDULFnbKykbupexQzCcTKVbKyp6tUhbKxsofwYbkUkYTsbJSc5G1ZGFJrNzOq+OWy0fDvMtznp5z3vP+jgDsqD1GrGbSsAmgHrhF7EluXo3pQ8smuo1gGlknUIlxLLFEaXxRDSt3spPRMnu4SQE9XvilDNgEhiL2QByoJJ6Q5cMZM+YioQIWZZqVy4WnQF0B/E/IuZc4/MWKKC+5BuJFOG7k8uEaxnARkg1ZJuyygP3/SiTrfX4Ll+vfAkv+Q7QbNO+8SOy4r9ZckAWq/yC61GPQqrbts/epvLblQ1degOhepqRaUmnPfhqt5cJBg1Ug9ovISRpXU2rpjfmWD8skBizQClARIboTGlNzyh/0XpEhs2yiwyQP1n4gLyQ34j/kV/mPSbX8QJWzxwXBuMFy4Mqn1Lp+FdXmE3tBcRDntyaSAAAAAElFTkSuQmCC");
      width: 17px;
      height: 15px;
      display: inline-block;
    }
  }
}

.ufooter{
  background-color: #498bf3;
  width: 100%;
  height: 20px;
  position: relative;
  &:after{
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #333333;
  }
}

/* СТРАНИЦА УСЛУГИ */

.services{
  background: #f3f3f3;
  padding: 0;
  .grid-container {
    background: #f3f3f3 url("../images/services.png") no-repeat right 150px top 20px;
    padding: 50px 0 190px 0;
    &:after{
      display: none;
    }
    .title{
      padding-top: 40px;
      padding-bottom: 0px;
    }
  }
}

.service-list{
  .list{
    position: relative;
    height: 420px;
    padding: 80px 0 140px 0;

    &:nth-child(2n+1){
      img{
        left: 50%;
        right: auto;
        transform: translate(0);
      }
    }

    &:nth-child(2n){
      .list-wrapper{
        padding-left: 110px;
        position: absolute;
        left: 50%;
        right: auto;
        p{
          padding-right: 0;
          width: 100%;
        }
      }
    }
  }
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    width: 50%;
    padding-right: 110px;
    color: #333333;
    font-family: $opensans;
    padding-top: 20px;
  }
  img{
    position: absolute;
    right: 50%;
    top: 0px;
    display: inline-block;
  }
}

/* СТРАНИЦА ВАКАНСИИ */

.vacancies{
  background: #f3f3f3;
  padding: 0;
  .grid-container {
    background: #f3f3f3 url("../images/img-vacancies.png") no-repeat right bottom -80px;
    padding: 70px 0 200px 0;
    &:after{
      display: none;
    }
    .title{
      padding-top: 40px;
      padding-bottom: 0px;
    }
  }
}

.vacancy{
  background-color: #333333;

  h3{
    color: #ffffff;
  }
  .orbit{
    position: relative;
    width: 400px;
    margin-top: 25px;
    a{
      border: 1px solid #f3f3f3;
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #f3f3f3;
      font-family: $montserrat;
      text-decoration: none;
      line-height: 30px;
      padding: 0 10px;
      margin-bottom: 10px;
      margin-right: 10px;
      &:hover{
        background-color: #498bf3;
      }
    }

    span{
      font-size: 16px;
      font-weight: 400;
      color: #f3f3f3;
      font-family: $montserrat;
    }
  }

  .orbit-controls{
    button{
      width: 50px;
      height: 40px;
      outline: 0;
      border: 0;
      background: #f3464f url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAYAAAC9QufkAAAAgElEQVQoU6WSwQ2DQAwEZ7tKJVSQHhLlCUKQT6ggjfJZdNJxiiwSjuCn7fGubIuNsH0HLkAjad7qSTnFQgaHnL9KelfBth9An5ufkpKDr1GUbY/ArRYstgM4Ae0vxbWmANYwpeccnMYE9RfQ1Vg4v7BV5e9TfQxItz3+JGHA7nsubLk6H7jPM1UAAAAASUVORK5CYII=") no-repeat center center;
      &:hover{
        background-color: #498bf3;
      }
    }
    .orbit-next, .orbit-previous{
      top: auto;
      bottom: -80px;
    }

    .orbit-next{
      left: 80px;
      bottom: -60px;
      transform: rotate(180deg);
    }
  }
}

/* СТРАНИЦА КАРТОЧКИ ВАКАНСИИ */

.vacancy-cart{
  background: #f3f3f3;
  padding-bottom: 120px;
  .grid-container{
    &:after{
      display: none;
    }
    .title{
      padding-top: 40px;
      padding-bottom: 0px;
    }
  }
}

.full{
  background: #f3f3f3;
  padding-bottom: 40px;
  .options{
    span{
      font-size: 16px;
      font-weight: 400;
      display: block;
      color: #333333;
      font-family: $montserrat;
      padding-bottom: 40px;
      b{
        font-weight: 700;
        padding-bottom: 5px;
        display: inline-block;
      }
    }
  }

  p{
    padding-top: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #333333;
    font-family: $opensans;
  }
}

/* СТРАНИЦА СПАСИБО */

.thanks{
  background: #f3f3f3;
  padding-bottom: 50px;
  padding-top: 0;
  .grid-container{
    background: #f3f3f3 url("../images/thanks.png") no-repeat right bottom -130px;
    padding-top: 150px;
    &:after{
      display: none;
    }
    .title{
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

/* СТРАНИЦА КЛИЕНТЫ */

.clients-page{
  background: #f3f3f3;
  padding: 0;
  overflow: hidden;
  .grid-container{
    padding: 50px 0 200px 0;

    &:after{
      background: url("../images/clients.png") no-repeat center center;
      width: 766px;
      height: 466px;
      position: absolute;
      right: -80px;
      bottom: -80px;
      content: '';
      transform: rotate(0deg);
    }
    .title{
      padding-top: 40px;
      padding-bottom: 0px;
    }
  }
}

.clients-text{
  .list-wrapper{
    .btn{
      background-color: #f3464f;
      margin-top: 40px;
      color: #ffffff;
      &:hover{
        background-color: #498bf3;
      }
    }
  }
}

.clients-logos{
  background-color: #333333;
  padding: 80px 0;
  h3{
    color: #f3f3f3;
    margin-bottom: 40px;
  }
  .client-case{
    width: 33%;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 60px;
    padding-right: 100px;
    position: relative;
    padding-top: 65px;
    p{
      color: #ffffff;
      font-family: $opensans;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    img{
      margin-bottom: 15px;
      position: absolute;
      top: 15px;
    }

    &:last-child{
      img{
        top: 0px;
      }
    }
  }
}

/* СТРАНИЦА КОНТАКТОВ */
.page-node-23 .region-content + .section-form{
  display: none;
}
.contacts-page{
  .send{
    .webform-component--text{
      padding-top: 0;
    }
  }
  .grid-container-inside-last{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between
  }
  background: #f3f3f3;
  #mini-panel-mcontacts{
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
    max-width: 1200px;
    margin: 0 auto;
    .map{
      background-color: #498bf3;
      padding: 50px 40px;
      padding-right: 0;
      position: relative;
      height: 540px;
      .panel-pane{
        width: 100%;
      }
      .pane-content{
        pointer-events: all !important;
      }
      .mapa{
        margin-top: 130px;
        height: 315px;
        width: 100%;
        padding-right: 40px;
      }
      p{
        color: #ffffff;
        font-family: $montserrat;
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 25px;
        padding-right: 240px;
      }
      .mapmonkey{
        width: 288px;
        height: 273px;
        background: url(../images/mapmonkey.png) no-repeat center center;
        position: absolute;
        right: -10px;
        top: -70px;
        display: inline-block;
        float: right;
        top: -100px !important;
      }
    }
  }
}

/* АДАПТИВ */

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .grid-container{
    padding: 0 20px;
  }
  nav{
    display: none !important;
  }
  .main{
    .dates{
      padding-left: 30px;
    }
    .title{
      font-size: 72px;
    }
    .grid-container{
      &:after{
        right: 10px;
      }
    }
    .workers{
      left: 30px;
      bottom: -90px;
      top: auto;
      right: auto;
    }

    .date-start{
      padding-left: 10px;
    }
  }
  .about{
    .grid-container{
      padding: 0;
    }
    &:after{
      display: none;
    }
    .text-about{
      padding-left: 20px;
      padding-right: 20px;
    }
    .tags{
      background-color: #333333;
      padding-right: 140px;
      padding-bottom: 40px;
      text-align: left;
      text-align-last: left;
      padding: 0;
      .tags-wrapper{
        padding-left: 0px;
        padding-top: 50px;
        left: 45% !important;
      }
      .tmonkey{
        left: 10% !important;
        background-position: center top !important;
        height: 370px;
        top: 25px !important;
      }
    }
    .panels-flexible-region-1-__________{
      width: 100%;
    }
    .panels-flexible-region-1-tags{
      width: 100%;
    }
    .panels-flexible-row-1-1-inside{
      padding: 0;
    }
  }

  .clients{
    padding-top: 380px;
    position: relative;
    overflow: hidden;
    .grid-container{
      padding: 0;
    }
    &:after{
      display: none;
    }
    .clients-img{
      position: absolute;
      top: 0;
      left: 0;
      height: 380px;
      &:before{
        width: 100px;
      }
    }

    .clients-list{
      background-color: #333333;
      padding-left: 20px;
      padding-right: 20px;
      img{
        margin-right: 30px;
      }
      .btn{
        display: block;
        width: 200px;
        text-align: center;
        margin-top: 30px;
      }

    }
  }

  .section-form{
    .send{
     padding-right: 0;
      h2{
        font-size: 36px;
      }
    }
    .monkey{
      background-position: left -40px center;
      span{
        top: 20px;
        left: 0;
      }
    }
  }

  .services{
    .grid-container{
      padding: 0px 20px 150px 20px;
      background: #f3f3f3 url(../images/services.png) no-repeat right 20px center;
      .title{
        padding-top: 50px;
        margin-left: 0;
      }
    }
  }

  .service-list{
    .grid-container{
      overflow: hidden;
    }
    .list:nth-child(n) {
      height: auto;
      padding-top: 80px;
      padding-bottom: 0px;
      img {
        position: static;
        display: block;
        width: 100%;
        padding-top: 80px;
      }
      .list-wrapper {
        position: static;
        padding-left: 0;
        h3{
          padding-bottom: 20px;
        }
      }
    }
    p{
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  .clients-page{
    .grid-container{
      padding-left: 20px;
      &:after{
        width: 72%;
        height: 80%;
        background-size: cover;
      }
      .title{
        position: relative;
        z-index: 2;
        margin-left: 0;
      }
    }
  }
  .clients-text{
    .grid-container{
      padding: 0;
    }
    .list-wrapper{
      p,h3{
        padding: 0 20px;
      }
      .btn{
        margin-left: 20px;
      }
    }
  }
  .clients-logos{
    padding-top: 70px;
    padding-bottom: 40px;
    .client-case{
      width: 48%;
    }
  }

  .vacancies{
    .grid-container{
      padding: 40px 0 170px 0;
      background-size: contain;
      .title{
        margin-left: 10px;
      }
      .date-start{
        padding-left: 15px;
      }
    }
  }

  .vacancy-cart{
    .title{
      font-size: 70px;
      margin-left: 10px;
    }
    .date-start{
      padding-left: 15px;
    }
  }

  .full{
    ul{
      list-style: none;
    }
    .options{
      padding-right: 20px;
    }
  }
  .contacts-page{
    .send{
      width: 45%;
      padding-left: 15px;
      h2{
        font-size: 30px;
      }
    }
    #mini-panel-mcontacts {
      .map {
        height: 700px;
        overflow: hidden;
        .mapa {
          margin-top: 300px !important;
        }
        .mapmonkey {
          top: 100px !important;
        }
      }
    }
  }
}

/* АДАПТИВ */

/* MOBILE ONLY */

  @media screen and (max-width: 39.9375em) {
    /* МОБИЛЬНОЕ МЕНЮ */

    /*анимированное раскрытие меню*/

    nav {
      width: 40px;
      height: 40px;
      background-color: #fccf55;
      display: block !important;
      position: relative;
      z-index: 4;
      margin-top: 10px;
      .menu {
        max-height: 0;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        background: #f3f3f3 url(../images/monkey-astro2.png) no-repeat center bottom -60%;
        transition: max-height .8s ease-out;
        overflow: hidden;
        width: 100%;
        z-index: 1;
      }

      #menuToggle {
        display: none;
      }

      #menuToggle:checked ~ .menu {
        max-height: 1000px;
        overflow: auto;
        display: block;
        transition: max-height .9s ease-in;
      }
      /*кнопка меню (гамбургер)*/

      .burger {
        display: block;
        width: 30px;
        height: 3px;
        background-color: #333333;
        position: relative;
        top: 8px;
        left: 5px;
        z-index: 2;
      }

      .burger:before {
        content: "";
        display: block;
        width: 30px;
        height: 3px;
        background-color: #333333;
        position: absolute;
        top: -8px;
        transition: transform .3s linear;
        -webkit-transition: transform .3s linear;
        -moz-transition: transform .3s linear;
        -o-transition: transform .3s linear;
      }

      .burger:after {
        content: "";
        display: block;
        width: 30px;
        height: 3px;
        background-color: #333333;
        position: absolute;
        bottom: -8px;
        transition: transform .3s linear;
      }

      #menuToggle:checked + label .burger {
        visibility: hidden;
      }

      #menuToggle:checked + label .burger:before {
        transform: rotate(40deg);
        top: 0;
        visibility: visible;
        transition: transform .3s linear;
        -webkit-transition: transform .3s linear;
        -moz-transition: transform .3s linear;
        -o-transition: transform .3s linear;
      }

      #menuToggle:checked + label .burger:after {
        transform: rotate(-40deg);
        bottom: 0;
        visibility: visible;
        transition: transform .3s linear;
        -webkit-transition: transform .3s linear;
        -moz-transition: transform .3s linear;
        -o-transition: transform .3s linear;
      }
      /*декорирование*/
      label {
        cursor: pointer;
        display: block;
        width: 40px;
        height: 40px;
        background-color: #fccf55;
        box-sizing: border-box;
        padding: 12px 0 12px 0;
        position: relative;
        z-index: 4;
      }
      .menu li {
        display: block;
        text-align: center;
        z-index: 4;
        position: relative;
        &:first-child {
          padding-top: 160px;
        }
        &:hover {
          display: block;
          background: #f3f3f3;
        }
        a {
          color: #333333;
          font-family: $opensans-c;
          font-size: 26px;
          font-weight: 700;
          line-height: 48px;
          display: block;
          padding: 7px 15px 7px 30px;
          cursor: pointer;
          &:hover {
            background: #f3f3f3;
          }
          &.active {
            color: #333333;
            font-family: $opensans-c;
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            padding: 0 60px;
            margin-top: 40px;
            cursor: pointer;

          }
        }
      }
    }
    .grid-container{
      padding: 0 10px;
    }

    .tabs{
      display: none;
    }

    header{
      overflow: hidden;
      .menu{
        display: none;
      }
      .logo{
        position: relative;
        z-index:5;
      }
    }
    footer{
      .menu{
        flex-wrap: wrap;
      }
    }
    .main{
      overflow: hidden;
      background: #f3f3f3;
      padding-top: 10px;
      padding-bottom: 110px;
      .grid-container{
        &:after{
          display: none;
        }
      }
      .title{
        font-size: 60px;
        padding-top: 0px;
        padding-bottom: 20px;
        line-height: 70px;
        margin: 0;
        margin-top: 20px;
      }
      .workers{
        left: 10px;
        bottom: -100px;
        top: auto;
      }

      .dates, .date-start{
        padding-left: 0px;
      }
    }
    .about{
      overflow: hidden;
      .grid-container{
        padding: 0;
      }
      &:after{
        display: none;
      }
      .text-about{
        padding-left: 10px;
        padding-right: 0px;
        p{
          font-size: 16px;
        }
      }
      .tags{
        background-color: #333333;
        padding-left: 20px;
        padding-bottom: 350px;
        text-align: left;
        text-align-last: left;
        background-position: center bottom -40px;
        padding: 0;
        .tags-wrapper{
          padding-left: 0px;
          left: 10px !important;
          top: 50px !important;
        }
        .tmonkey{
          left: 10% !important;
          top: 335px !important;
        }
      }
      .panels-flexible-region-1-__________{
        width: 100%;
      }
      .panels-flexible-region-1-tags{
        width: 100%;
        padding-bottom: 250px;
      }
      .panels-flexible-row-1-1-inside{
        padding: 0;
      }
    }

    .clients{
      overflow: hidden;
      padding-top: 380px;
      position: relative;
      overflow: hidden;
      .grid-container{
        padding: 0;
      }
      &:after{
        display: none;
      }
      .clients-img{
        position: absolute;
        top: 0;
        left: 0;
        height: 380px;
        &:after{
          left: 50%;
          transform: translate(-40%,0);
        }
        &:before{
          width: 100px;
        }
      }

      .clients-list{
        overflow: hidden;
        background-color: #333333;
        padding-left: 10px;
        padding-right: 10px;
        img{
          margin-right: 30px;
        }
        .btn{
          display: block;
          width: 200px;
          text-align: center;
          margin-top: 30px;
        }

      }
    }

    .section-form{
      overflow: hidden;
      padding: 70px 0 70px 0;
      .send{
        width: 100%;
        padding-right: 0;
        h2{
          font-size: 30px;
        }
        form .checkbox{
          width: 100%;
        }
      }
      .monkey{
        display: none;
      }
    }

    .services{
      overflow: hidden;
      padding-bottom: 30px;
      .grid-container{
        padding: 0px 10px 300px 10px;
        background: #f3f3f3 url(../images/services.png) no-repeat center bottom;
        .title{
          padding-top: 0;
          margin-top: 0;
        }
        .date-start{
          padding-top: 20px;
        }
      }
    }

    .service-list{
      overflow: hidden;
      h3{
        padding-bottom: 10px !important;
      }
      .grid-container{
        overflow: hidden;
      }
      .list:nth-child(n) {
        height: auto;
        padding-top: 40px;
        padding-bottom: 20px;
        img {
          position: static;
          display: block;
          width: 100%;
        }
        .list-wrapper {
          position: static;
          padding-left: 0;
        }
      }
      p{
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    .clients-page{
      overflow: hidden;

      .grid-container{
        padding-left: 10px;
        padding-top: 0;
        padding-bottom: 310px;
        &:after{
          display: block;
          width: 100%;
          height: 400px;
          background-size: cover;
          background: url(../images/clients.png) no-repeat center bottom -90px;
          position: absolute;
          left: 0;
          bottom: -90px;
          content: '';
          transform: rotate(0deg);
        }
        .date-start{
          padding-top: 20px;
        }
        .title{
          position: relative;
          z-index: 2;
          margin-left: 0;
          padding-top: 0;
          margin-top: 0;
        }
      }
    }
    .clients-text{
      overflow: hidden;
      .grid-container{
        padding: 0;
      }
      .list-wrapper{
        p,h3{
          padding: 0 20px;
        }
        .btn{
          margin-left: 20px;
        }
      }
      .list:nth-child(n){
        padding-bottom: 0;
        img{
          padding-top: 40px;
          width: auto;
          height: 360px;
        }
      }
    }
    .clients-logos{
      overflow: hidden;
      padding-top: 70px;
      padding-bottom: 20px;
      .client-case{
        width: 98%;
        padding-right: 10px;
      }
    }

    .vacancy-cart{
      .title{
        font-size: 50px;
      }
    }

    .full{
      ul{
        list-style: none;
      }
      .options{
        padding-right: 20px;
      }
    }

    .vacancies{
      padding-bottom: 0;
      .grid-container{
        padding: 0px 0 450px 0;
        background-position: right -250px bottom -80px;
        .title {
          padding-top: 0;
          padding-left: 5px;
          margin-top: 0;
        }
        .date-start{
          padding-left: 10px;
        }
      }
    }

    .vacancy .orbit{
      margin-bottom: 60px;
      margin-top: 15px;
    }

    .contacts-page{
      .send{
        width: 90%;
        margin: 0 auto;
      }
      .map{
        overflow: hidden;
        width: 90%;
        margin: 0 auto;
        .mapmonkey{
          display: none !important;
        }
      }
    }
  }
