// stylelint-disable scss/dollar-variable-empty-line-before
$global-width: 1200px;
$grid-column-count: 12;
$grid-container-padding: 10px;
// COLORS

$color-black: #000;
$color-white: #fff;

// FONTS

$opensans-c: 'Open Sans Condensed', sans-serif;
$montserrat: 'Montserrat Alternates', sans-serif;
$opensans: 'Open Sans', sans-serif;
$badscript: 'Bad Script', cursive;